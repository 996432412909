import FormErrors from '../../components/FormFieldErrors.vue'
import TermsAndConditions from './TermsAndConditions.vue'
import SystemError from '../../components/SystemError.vue'
import useBackend from '../../components/useBackend'
import BusinessDetails from './BusinessDetails.vue'
import { PasswordToggle } from '../../components/PasswordToggle.js'
export default {
  setup() {
    const { callBackend, systemError } = useBackend()

    return {
      callBackend,
      systemError,
    }
  },
  name: 'Registration',
  components: {
    BusinessDetails,
    SystemError,
    FormErrors,
    TermsAndConditions,
  },
  data() {
    return {
      count: 0,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        organization: null,
        organization_name: '',
        pending: false,
        job_title: '',
        country: null,
        agreed_terms: false,
        optin_marketing: false,
      },
      personalFields: ['first_name', 'last_name', 'email', 'password'],
      pendingRequired: false,
      kind: '',
      countries: [],
      domainClient: null,
      selectedOrganization: null,
      serverSideContent: null,
      csrfToken: '',
      errors: {
        first_name: [],
        last_name: [],
        email: [],
        password: [],
        organization: [],
        organization_name: [],
        job_title: [],
        country: [],
        agreed_terms: [],
      },
      isSaving: false,
      currentStep: null,
      steps: [
        {
          name: 'personal',
          label: 'Enter your data',
        },
        {
          name: 'business',
          label: 'Enter business data',
        },
        {
          name: 'confirm',
          label: 'Confirm email',
        },
        {
          name: 'done',
          label: 'Start using Solarplaza',
        },
      ],
      urls: {
        domainLookup: '',
        login: '',
        organizationAutocomplete: '',
        privacyPolicy: '',
        registerPersonal: '',
        solarplazaLogo: '',
        termsAndConditions: '',
        save: '',
        validate: '',
      },
      validateTimeout: null,
      organizationInputOpen: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      PasswordToggle('#id_password', '.togglePassword')
    })
    this.currentStep = 'personal'
    let initialData = JSON.parse(
      document.getElementById('initialData').textContent
    )
    this.setIfDefined(initialData)
    // get content from div #vue-registration-server-side
    let serverSideContentElement = document.getElementById(
      'vue-registration-server-side'
    )
    if (serverSideContentElement) {
      this.serverSideContent = serverSideContentElement.innerHTML
    }

    // restore all fields from initialData session
    for (let key in initialData.session) {
      this.form[key] = initialData[key]
    }
    Object.keys(initialData.session).forEach((field) => {
      this.form[field] = initialData.session[field]
    })

    if (this.kind !== 'business') {
      this.steps = this.steps.filter((step) => step.name !== 'business')
    }
  },
  computed: {
    personalDataValid() {
      let requiredFields = [...this.personalFields]
      if (this.kind === 'personal') {
        requiredFields.push('agreed_terms')
      }
      return this.dataValid(requiredFields)
    },
    businessDataValid() {
      let requiredFields = ['job_title', 'country', 'agreed_terms']
      return this.dataValid(requiredFields)
    },
    saveButtonDisabled() {
      if (this.isSaving) {
        return true
      }
      if (this.kind === 'personal') {
        return !this.personalDataValid
      }
      return !(this.personalDataValid && this.businessDataValid)
    },
  },
  methods: {
    setIfDefined(data) {
      let fields = [
        'countries',
        'csrfToken',
        'currentStep',
        'form',
        'kind',
        'urls',
      ]
      fields.forEach((field) => {
        if (data[field]) {
          this[field] = data[field]
        }
      })
    },
    dataValid: function (fields) {
      // Check if all required fields are filled and valid
      for (let field of fields) {
        if (!this.form[field]) {
          console.error('field not filled', field)
          return false
        }
        if (this.errors[field].length > 0) {
          console.error('field not valid', field)
          return false
        }
      }
      return true
    },
    goToPersonalStep() {
      this.currentStep = 'personal'
    },
    goToBusinessStep() {
      // validate personal data, as it might not be validated yet (if pre-filled, or through fast clicking)
      this.validate(this.personalFields).then(() => {
        this.currentStep = 'business'
      })
    },
    selectOrganization(organization, source) {
      // copy organization object and add source property
      this.selectedOrganization = Object.assign({}, organization, {
        source: source,
      })
      this.validate(['organization', 'email', 'pending'])
    },
    clearSelectedOrganization() {
      this.selectedOrganization = null
    },
    validate(fields) {
      // create object with data for all fields
      let payload = { kind: this.kind, form: {} }
      for (let field of fields) {
        let value = this.form[field]
        if (field === 'organization' && this.selectedOrganization) {
          value = this.selectedOrganization.id
        }
        payload.form[field] = value
      }
      return this.callBackend({
        url: this.urls.validate,
        csrfToken: this.csrfToken,
        payload: payload,
      })
        .then((data) => {
          //  Update this.errors with data.errors
          Object.keys(data.errors).forEach((field) => {
            this.errors[field] = data.errors[field]
          })
          this.pendingRequired = data.pendingRequired
        })
        .catch((err) => {})
    },
    delayedValidate(fields) {
      // validate after some delay, cancel previous timeout
      clearTimeout(this.validateTimeout)
      this.validateTimeout = setTimeout(() => {
        this.validate(fields)
      }, 1000)
    },
    save() {
      this.isSaving = true
      let payload = {
        kind: this.kind,
        form: {
          ...this.form,
          // if domains do not match, member will be added with 'pending' role. If `pending` is set to true,
          // the form will not raise an error in this case
          pending: true,
          organization: this.selectedOrganization
            ? this.selectedOrganization.id
            : null,
        },
      }

      this.callBackend({
        url: this.urls.save,
        csrfToken: this.csrfToken,
        payload: payload,
      })
        .then((data) => {
          if (data.status === 'ok') {
            window.location.href = data.redirect
          } else {
            Object.keys(this.errors).forEach((key) => {
              this.errors[key] = data.errors[key] || []
            })
          }
          this.isSaving = false
        })
        .catch((err) => {
          this.isSaving = false
        })
    },
  },
}
