<template>
  <div class="row">
    <div class="col-md-6 personal-information">
      <h3 class="display-3 margin-2 bold blue-light">Attendee information</h3>

      <div class="row">
        <div class="attendee-details">
          <ul class="nav nav-tabs">
            <li
              class="nav-item"
              role="presentation"
              v-for="(attendee, index) in attendees"
              :key="index"
            >
              <button
                class="nav-link"
                :id="'attendee-nav' + (index + 1)"
                type="button"
                role="tab"
                :class="index === activeTab ? 'active' : ''"
                @click="activateTab(index)"
              >
                Ticket {{ index + 1 }}
                <i
                  v-if="tabHasErrors(index)"
                  class="fal fa-exclamation-triangle"
                ></i>
              </button>
            </li>
          </ul>
          <div class="tab-content form-box" id="ticket-information">
            <div
              class="tab-pane fade"
              role="tabpanel"
              v-for="(attendee, index) in attendees"
              :key="index"
              :id="'ticket' + (index + 1)"
              :class="index === activeTab ? ' show active' : ''"
            >
              <div class="col-12">
                <p class="large">
                  <span class="large bold blue-light"
                    >{{ getAttendeeTicket(attendee).category }}:</span
                  >
                  {{ getAttendeeTicket(attendee).name }}<br />
                  <span class="small blue-light">{{ event.title }}</span>
                </p>

                <label
                  :for="`skip-${index}`"
                  v-if="attendee.isEmptySlot && !attendee.isSpeaker"
                >
                  <input
                    class="margin-0"
                    :id="`skip-${index}`"
                    type="checkbox"
                    :checked="attendee.skip"
                    @change="setAttendeeSkip(index, $event.target.checked)"
                  /><span class="margin-0">
                    Skip this attendee slot for now</span
                  >
                </label>

                <template v-if="!attendee.isEmptySlot">
                  <p class="large display-5 bold blue-light margin-0">
                    {{ attendee.firstName }} {{ attendee.lastName }}
                  </p>
                  <p class="large display-5 blue-light">
                    {{ attendee.organizationName }}
                  </p>
                </template>
                <div
                  v-if="isLocked(attendee)"
                  class="notification yellow small mb-3"
                >
                  <i class="fa-regular fa-circle-info"></i> This ticket is
                  reserved for an event speaker and therefore cannot be
                  changed.<br />
                  Please contact Solarplaza for any adjustments.
                </div>
                <div :class="{ skip: attendee.skip || isLocked(attendee) }">
                  <div class="row">
                    <template v-if="fields.includes('firstName')">
                      <div class="col-12 col-md-6">
                        <label for="first-name">First name:</label>
                        <input
                          type="text"
                          id="first-name"
                          v-model="attendee.firstName"
                          maxlength="150"
                          @change="$emit('validate', index, ['firstName'])"
                          @input="
                            $emit('delayed-validate', index, ['firstName'])
                          "
                          @focusout="$emit('validate', index, ['firstName'])"
                        />

                        <FormErrors
                          :errors="getError('attendees', index, 'firstName')"
                        ></FormErrors>
                      </div>
                    </template>

                    <template v-if="fields.includes('lastName')">
                      <div class="col-12 col-md-6">
                        <label for="last_name">Last name:</label>
                        <input
                          type="text"
                          id="last-name"
                          v-model="attendee.lastName"
                          maxlength="150"
                          @change="$emit('validate', index, ['lastName'])"
                          @input="
                            $emit('delayed-validate', index, ['lastName'])
                          "
                          @focusout="$emit('validate', index, ['lastName'])"
                        />
                        <FormErrors
                          :errors="getError('attendees', index, 'lastName')"
                        ></FormErrors>
                      </div>
                    </template>
                  </div>

                  <template v-if="fields.includes('organizationName')">
                    <label for="organizationName">Organization Name:</label>
                    <input
                      type="text"
                      id="organizationName"
                      v-model="attendee.organizationName"
                      maxlength="254"
                      @change="$emit('validate', index, ['organizationName'])"
                      @input="
                        $emit('delayed-validate', index, ['organizationName'])
                      "
                      @focusout="$emit('validate', index, ['organizationName'])"
                    />
                    <FormErrors
                      :errors="getError('attendees', index, 'organizationName')"
                    ></FormErrors>
                  </template>

                  <template v-if="fields.includes('organizationType')">
                    <label for="organizationType">Organization Type:</label>
                    <select
                      id="organizationType"
                      v-model="attendee.organizationType"
                    >
                      <option
                        v-for="option in organizationTypes"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>

                    <FormErrors
                      :errors="getError('attendees', index, 'organizationType')"
                    ></FormErrors>
                  </template>

                  <template v-if="fields.includes('jobTitle')">
                    <label for="jobTitle">Job Title:</label>
                    <input
                      type="text"
                      id="jobTitle"
                      v-model="attendee.jobTitle"
                      maxlength="254"
                      @change="$emit('validate', index, ['jobTitle'])"
                      @input="$emit('delayed-validate', index, ['jobTitle'])"
                      @focusout="$emit('validate', index, ['jobTitle'])"
                    />
                    <FormErrors
                      :errors="getError('attendees', index, 'jobTitle')"
                    ></FormErrors>
                  </template>
                  <template v-if="fields.includes('email')">
                    <label for="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      v-model="attendee.email"
                      maxlength="254"
                      @change="$emit('validate', index, ['email'])"
                      @input="$emit('delayed-validate', index, ['email'])"
                      @focusout="$emit('validate', index, ['email'])"
                    />
                    <FormErrors
                      :errors="getError('attendees', index, 'email')"
                    ></FormErrors>
                  </template>

                  <template v-if="fields.includes('phone')">
                    <label for="phone">Phone:</label>
                    <input
                      type="text"
                      id="phone"
                      v-model="attendee.phone"
                      maxlength="254"
                      @change="$emit('validate', index, ['phone'])"
                      @input="$emit('delayed-validate', index, ['phone'])"
                      @focusout="$emit('validate', index, ['phone'])"
                    />
                    <FormErrors
                      :errors="getError('attendees', index, 'phone')"
                    ></FormErrors>
                  </template>
                </div>
                <div :class="{ skip: attendee.skip }">
                  <template
                    v-if="fields.includes('dietary') && !attendee.isVirtual"
                  >
                    <label for="dietary">Dietary requirements:</label>
                    <select id="dietary" v-model="attendee.dietary">
                      <option
                        v-for="option in dietaryOptions"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>

                    <FormErrors
                      :errors="getError('attendees', index, 'dietary')"
                    ></FormErrors>
                    <template v-if="attendee.dietary === 'other'">
                      <label for="dietaryOther">Please specify:</label>
                      <input
                        type="text"
                        id="dietaryOther"
                        v-model="attendee.dietaryOther"
                        maxlength="254"
                    /></template>

                    <FormErrors
                      :errors="getError('attendees', index, 'dietaryOther')"
                    ></FormErrors>
                  </template>

                  <FormErrors
                    :errors="getError('attendees', index, '__all__')"
                  ></FormErrors>

                  <div
                    v-if="
                      !attendee.isVirtual && getAvailableAddOns().length > 0
                    "
                  >
                    <hr />
                    <p class="blue">
                      Choose add-ons to enhance your event experience:
                    </p>
                    <div
                      v-for="(addOn, addOnIndex) in getAvailableAddOns()"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-12 add-on-box">
                          <input
                            class="margin-0"
                            :id="`add-on-${index}-${addOnIndex}`"
                            type="checkbox"
                            :checked="getAddOnSelected(addOn, attendee)"
                            :disabled="getAddOnDisabled(addOn, attendee)"
                            @change="
                              setAddOnSelected(
                                addOn,
                                attendee,
                                addOnIndex,
                                $event.target.checked
                              )
                            "
                          />
                          <label
                            :for="`add-on-${index}-${addOnIndex}`"
                            class="row"
                          >
                            <div class="col-8 text">
                              <p class="large blue-light bold margin-0">
                                {{ addOn.name }}
                              </p>
                              <p
                                v-if="getAddOnDisabled(addOn, attendee)"
                                class="margin-0 blue-light"
                              >
                                Included for this ticket
                              </p>
                              <p v-else class="green bold margin-0">
                                {{ addOn.currency }}{{ addOn.price }}
                              </p>
                            </div>

                            <div
                              v-if="addOn.image"
                              class="col-4 visual"
                              :style="{
                                backgroundImage: `url(${addOn.image})`,
                              }"
                            ></div>
                            <div
                              v-else
                              class="col-4 visual"
                              :style="{
                                backgroundImage: `url(${urls.addOnDefaultImage})`,
                              }"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-4 mb-0" />
                </div>
                <button
                  v-if="index < attendees.length - 1"
                  class="medium blue-light mt-4"
                  v-on:click="nextAttendee(index)"
                >
                  Next attendee <i class="fas fa-chevron-right"></i>
                </button>
                <button
                  v-else
                  class="medium green mt-4"
                  v-on:click="$emit('done-attendees')"
                  :disabled="continueButtonDisabled"
                >
                  Continue
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-1"></div>

    <div class="d-none d-md-block col-md-5 order-summary">
      <h3 class="display-3 bold margin-2 blue-light">Your order</h3>

      <OrderSummary :event="event" :summary="summary"></OrderSummary>
    </div>
  </div>
</template>

<script>
import FormErrors from '../../components/FormFieldErrors.vue'
import OrderSummary from './OrderSummary.vue'

export default {
  name: 'Attendees',
  components: { FormErrors, OrderSummary },
  props: {
    attendees: Array,
    organizationTypes: Array,
    dietaryOptions: Array,
    event: Object,
    fields: Array,
    flow: String,
    products: Array,
    summary: Object,
    urls: Object,
    allowSkip: { type: Boolean, default: false },
    continueAllowed: Boolean,
    getError: Function,
    getStepErrors: Function,
  },
  emits: ['done-attendees', 'validate', 'delayed-validate'],
  data() {
    return {
      activeTab: 0,
    }
  },
  methods: {
    getAvailableAddOns() {
      return this.products.filter((product) => {
        return product.type === 'add_on' && !product.soldOut
      })
    },
    getAddOnSelected(product, attendee) {
      return (
        (attendee.originalProductIds &&
          attendee.originalProductIds.includes(product.id)) ||
        attendee.productIds.includes(product.id)
      )
    },
    getAddOnDisabled(product, attendee) {
      return (
        attendee.originalProductIds &&
        attendee.originalProductIds.includes(product.id)
      )
    },
    setAddOnSelected(product, attendee, index, checked) {
      attendee.productIds = attendee.productIds.filter((attendeeProductId) => {
        return attendeeProductId !== product.id
      })

      if (checked) {
        attendee.productIds.push(product.id)
      }
      this.$emit('validate', index, ['products'])
    },
    isLocked(attendee) {
      if (this.flow === 'fill-slots') {
        return attendee.isSpeaker
      }
      return false
    },
    nextAttendee(attendeeIndex) {
      this.$emit('validate', attendeeIndex, this.fields)
      this.activeTab++
      if (this.activeTab >= this.attendees.length) {
        this.activeTab = 0
      }
    },
    activateTab(index) {
      this.$emit('validate', index, this.fields)
      this.activeTab = index
    },
    tabHasErrors(index) {
      let stepErrors = this.getStepErrors('attendees')
      return stepErrors.some((error) => error.index === index && error.show)
    },
    getAttendeeTicket(attendee) {
      let productIds = attendee.productIds

      // if attendee has a originalProductIds property, use that instead
      // this is used in AddAddOns and FillSlots
      if (attendee.originalProductIds.length > 0) {
        productIds = attendee.originalProductIds
      }
      let products = this.products.filter(
        (product) =>
          productIds.includes(product.id) && product.type === 'ticket'
      )
      return products[0]
    },
    setAttendeeSkip(attendeeIndex, checked) {
      this.attendees[attendeeIndex].skip = checked
      this.$emit('validate', attendeeIndex, this.fields)
    },
  },
  computed: {
    continueButtonDisabled() {
      return !this.continueAllowed
    },
  },
}
</script>

<style scoped>
.skip {
  opacity: 0.5;
  pointer-events: none;
}
</style>
