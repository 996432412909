export function PasswordToggle(passwordSelector, toggleIconSelector) {
  const passwordField = document.querySelector(passwordSelector)
  const togglePassword = document.querySelector(toggleIconSelector)

  if (passwordField && togglePassword) {
    togglePassword.addEventListener('click', () => {
      const type =
        passwordField.getAttribute('type') === 'password' ? 'text' : 'password'
      passwordField.setAttribute('type', type)
      togglePassword.classList.toggle('fa-eye')
      togglePassword.classList.toggle('fa-eye-slash')
    })
  }
}
