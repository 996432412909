<template>
  <div class="row" v-if="1 == 1">
    <div class="col-12 col-md-6">
      <h3 class="display-3 bold margin-2 blue-light">{{ currentStepLabel }}</h3>
      <a v-on:click="this.$emit('go-to-step', 'tickets')" class="ms-3">Edit</a>
      <OrderSummary :event="event" :summary="summary"></OrderSummary>
    </div>
    <div class="col-md-1"></div>
    <div v-if="!isAuthenticated" class="col-12 col-md-5">
      <h3 class="display-3 margin-2 bold blue-light">Checkout</h3>
      <p class="large">
        Log in or
        <a
          :href="urls.register + '?next=' + urls.authenticated"
          class="large"
          target="_blank"
          >create an account</a
        >
        to proceed to securing your to ticket. Where industry leaders convene to
        drive solar energy innovation for businesses.
      </p>
      <form method="post" :action="urls.login">
        <input type="hidden" name="csrfmiddlewaretoken" :value="csrfToken" />
        <input type="hidden" name="next" :value="urls.authenticated" />
        <input type="text" name="email" placeholder="email" />
        <input
          type="password"
          name="password"
          placeholder="password"
          id="checkout_password"
        />
        <span class="togglePassword password-eye fa-solid fa-eye"></span>
        <input type="submit" class="orange medium" value="Log in" />
      </form>
    </div>
    <div v-else class="col-12 col-md-5">
      <h3 class="display-3 margin-2 bold blue-light">Checkout</h3>
      <p>Proceed to checkout.</p>
      <button
        class="green medium float-end"
        @click.prevent="$emit('done-basket')"
      >
        Checkout <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { PasswordToggle } from '../../components/PasswordToggle.js'
import OrderSummary from '../shared/OrderSummary.vue'

export default {
  name: 'Basket',
  components: { OrderSummary },
  props: {
    event: Object,
    tickets: Object,
    summary: Object,
    currentStepLabel: String,
    isAuthenticated: Boolean,
    urls: Object,
    csrfToken: String,
    getError: Function,
  },
  emits: ['done-basket'],
  mounted() {
    PasswordToggle('#checkout_password', '.togglePassword')
  },
}
</script>
