export default {
  id: 0,
  firstName: '',
  lastName: '',
  organizationName: '',
  organizationType: '',
  jobTitle: '',
  email: '',
  phone: '',
  dietary: '',
  dietaryOther: '',
  originalProductIds: [],
  productIds: [],
  isEmptySlot: false,
  isSpeaker: false,
  isVirtual: false,
  ticketNumber: '',
}
