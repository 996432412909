<template>
  <div class="card mb-5" v-if="!finished">
    <div class="card-body">
      <h2 class="card-title display-5 blue-light bold">
        Show your profile on the attendee list?
      </h2>
      <p class="blue">
        Make your profile visible to help you and others connect before and
        during the event
      </p>
    </div>
    <div
      class="card-footer pt-3 row justify-content-center align-items-baseline"
    >
      <label id="accept-for-future-event" claSS="d-flex justify-content-center"
        ><input
          type="checkbox"
          class="mx-3"
          v-model="model.rememberChoice"
          id="accept-for-future-events"
        />
        Remember my choice for future events</label
      >

      <div class="col-auto">
        <button @click="decline" class="button orange outline small">
          Decline
        </button>
      </div>
      <div class="col-auto mb-4">
        <button @click="accept" class="button green medium">Accept</button>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../../api'

export default {
  name: 'EventAttendeeStatusDialog',
  data() {
    return {
      loading: false,
      finished: false,
      model: {
        rememberChoice: true,
      },
    }
  },
  props: {
    event: {
      type: String,
      required: true,
    },
  },
  methods: {
    accept() {
      post(`/event/${this.event}/update-attendee-status/`, {
        show_in_attendee_list: true,
        always_show_in_attendee_list: this.model.rememberChoice,
      }).then(() => {
        this.finished = true
        location.reload()
      })
    },
    decline() {
      post(`/event/${this.event}/update-attendee-status/`, {
        show_in_attendee_list: false,
        always_show_in_attendee_list: this.model.rememberChoice,
      }).then(() => {
        this.finished = true
      })
    },
  },
}
</script>

<style scoped></style>
